@import "../../style/globals.scss";
.container-home {
  padding-top: 7rem;
}
#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  .profile {
    width: 350px;
    height: 350px;
    img {
      width: 100%;
      height: 350px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }
  .profile_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    h3 {
      margin-top: 1rem;
      color: $white;
      font-size: 2rem;
      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
      span {
        color: $cyan;
      }
    }
  }
  .job {
    color: $white;
    opacity: 0.6;
    font-size: 1.25rem;
  }
  .text {
    margin-top: 2rem;
    text-align: center;
    font-size: 5rem;
    color: $white;
    line-height: 1.3;
    @media screen and (max-width: 800px) {
      font-size: 3.5rem;
    }
  }
  a {
    margin-top: 3rem;
    color: $black;
    background-color: $white;
    padding: 0.65rem 1rem;
    border-radius: 10px;
  }
}

@import "../../style/globals.scss";

.select {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 5rem;

  button {
    color: $black;
    background: $white;
    padding: 0.65rem 1rem;
    border-radius: 10px;
    font-size: 1rem;
    text-transform: capitalize;
    cursor: pointer;
    border: none;
    margin-top: 1rem;

    &.active {
      background: $cyan;
      color: $white;
    }
  }
}

.skills {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  row-gap: 4rem;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: $white;
    font-size: 1.1rem;
    svg {
      width: 80px;
      height: 80px;
      color: $white;
    }
  }
}

.experiencs {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  .experience {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    span {
      font-size: 2.75rem;
      padding-left: 2.5rem;

      @media screen and (max-width: 405px) {
        font-size: 1rem;
      }
    }
    .position {
      width: 300px;
      padding-left: 2.5rem;
      h3 {
        font-size: 1.25rem;
        @media screen and (max-width: 405px) {
          font-size: 0.85rem;
        }
      }
      p {
        font-size: 0.85rem;
        opacity: 0.6;

        @media screen and (max-width: 405px) {
          font-size: 0.65rem;
        }
      }
    }
  }
}

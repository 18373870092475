@import "../../style/globals.scss";
.contact {
  margin-top: 1rem;
}
.contact_form {
  background: $white;
  padding: 2rem;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 15px;
  .smaller {
    width: 95%;
    margin: auto;
  }
  .contact_info {
    display: flex;
    flex-wrap: wrap;
    color: $black;
    gap: 1rem;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
      justify-content: center;
    }
    margin-top: 2rem;
    a {
      color: $black;
    }
  }
}

@import "../../style/globals.scss";

.workImages {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .workImage {
    max-width: 290px;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &__title {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
    }
    &__tags {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      .tag {
        color: $white;
        font-size: 0.75rem;
        font-weight: bold;
        display: flex;
        padding: 0.5rem;
        border-radius: 10px;
        border: 2px solid $white;
      }
    }

    .portfolio__info {
      position: relative;
      z-index: 200;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0.5rem;
      width: 100%;
    }
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 1rem;
    }

    .hoverLayer {
      background: $cyan;
      border-radius: 1rem;
      width: 100%;
      height: 100%;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      gap: 1.5rem;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &__icon {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        font-weight: 900;
      }

      a {
        width: 50px;
        height: 50px;
        background: rgba(255, 255, 255, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: $white;

        svg {
          color: $white;
          font-size: 2rem;
        }
      }
    }
  }
}

.talk {
  margin-top: 3rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  gap: 1rem;

  .talk_left {
    h3 {
      font-size: 1.5rem;

      span {
        font-size: 3.5rem;
        color: #8c12f0;

        @media screen and (max-width: 599px) {
          font-size: 2rem;
        }

        @media screen and (max-width: 360px) {
          font-size: 1.5rem;
        }
      }
    }
  }
  .talk_right {
    align-self: flex-end;
    margin-bottom: 1.5rem;
    background: #8c12f0;
    padding: 0.65rem 1rem;
    border-radius: 10px;
    font-size: 0.85rem;

    a {
      text-decoration: none;
      color: #fff;
      text-transform: capitalize;
    }
  }
}

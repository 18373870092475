@import "../../style/globals.scss";

.about_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  position: relative;
  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
    margin-bottom: 7rem;
  }

  .about_left {
    background: $cyan;

    max-width: 650px;
    max-height: 550px;
    justify-self: center;
    border-radius: 0.5rem;
    box-shadow: 0 0 0px 4px $cyan;

    img {
      object-fit: cover;
      width: 650px;
      height: 550px;
      border-radius: 0.5rem;
      @media screen and (max-width: 710px) {
        max-width: 100%;
      }
    }
  }
  .about_right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 150px;
    color: #fff;
    background-color: rgba(19, 18, 18, 0.7);
    box-shadow: 0 0 0px 4px $cyan;
    padding: 2rem;
    border-radius: 0.5rem;
    @media screen and (max-width: 1150px) {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      position: initial;
      margin-top: 2rem;
    }
    h3 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1rem;
      margin-bottom: 1rem;
      max-width: 450px;
    }
    .bio {
      display: flex;
      font-size: 1rem;
      margin-bottom: 1rem;
      .bioKey {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-right: 3rem;
      }
    }
    a {
      color: $black;
      background-color: $white;
      padding: 0.65rem 1rem;
      border-radius: 0.75rem;
      font-size: 0.85rem;
    }
  }
}

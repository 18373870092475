@import "./style/globals.scss";

* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
img {
  width: 100%;
}
@media screen and (max-width: 500px) {
  .container {
    padding: 7rem 1rem 0 1rem;
  }
}
.container {
  max-width: 1224px;
  min-height: 104vh;
  padding: 7rem 1.5rem 0 1.5rem;
  margin: 0 auto;
}

body {
  background-blend-mode: color-dodge;
  background-image: url(./assets/cubes.png);
  background-color: $backgroundColor;
}

.title {
  text-align: center;
  margin-bottom: 3rem;
}
.title span {
  color: $cyan;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: 5px;
}
.title h2 {
  color: $white;
  font-size: 3.25rem;
  position: relative;
  font-weight: 600;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #ffffff40;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff40;
}

*::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:active {
  background-color: #ffffff60;
}

.arrow-container {
  position: fixed;
  top: 50%;
  left: 200px;
  @media screen and (max-width: 850px) {
    display: none;
  }
}

.arrow-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.arrow-button svg {
  width: 20px;
  height: 20px;
  fill: #000;
}
